<template>
  <span
    v-if="platform"
    :key="keys.UserPlatform"
  >
    <font-awesome-icon
      :icon="['fas', 'circle']"
      :class="{
        'text-success': platform === 'hostesswing',
        'text-success': platform === 'club',
        'text-warning': platform === 'wing'
      }"
    />&nbsp;
    <span
      v-if="!state || isLocked"
      style="width: 120px"
      @dblclick.prevent.stop="toggle"
    >
      {{ displayPlatform }}
    </span>
    <b-form-select
      v-else
      v-model="platform"
      :options="options"
      size="sm"
      style="width: 120px"
    />

    <b-link
      v-if="!isLocked"
      @click="toggle"
    >
      <font-awesome-icon
        size="sm"
        class="ml-2"
        :class="state ? 'text-success' : 'text-danger'"
        :icon="['fas', state ? 'check' : 'pen']"
      />
    </b-link>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'UserPlatform',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    options: {
      type: Array,
      default: () => ([])
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      default: -1
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName'
    }),
    ...mapComputedFormFields('customerEdit', [
      'platform'
    ]),
    ...mapComputedStateFields('customerEdit', []),
    displayPlatform () {
      let str = ''
      if (this.options && this.options.length) {
        const item = this.options.find(i => i.value === this.platform)
        if (item) {
          str = item.text
        }
      }
      return str
    }
  },
  watch: {
    platform (n, o) {
      if (n && n !== o) {
        this.state = false
      }
    }
  },
  methods: {
    toggle () {
      this.state = !this.state
    }
  }
}
</script>

<style scoped>

</style>
