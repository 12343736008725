var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.status)?_c('span',{key:_vm.keys.UserStatus},[_c('font-awesome-icon',{class:{
      'text-success': _vm.status === 'active',
      'text-warning': _vm.status === 'inactive',
      'text-warning': _vm.status === 'suspended',
      'text-danger': _vm.status === 'deleted',
      'text-primary': _vm.status === 'register',
      'text-secondary': _vm.status === 'hidden',
    },attrs:{"icon":['fas', 'circle']}}),_vm._v(" \n  "),(!_vm.state || _vm.isLocked)?_c('span',{staticStyle:{"width":"120px"},on:{"dblclick":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.displayStatus)+"\n  ")]):_c('b-form-select',{staticStyle:{"width":"120px"},attrs:{"options":_vm.options,"size":"sm"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_vm._v(" "),(!_vm.isLocked)?_c('b-link',{on:{"click":_vm.toggle}},[_c('font-awesome-icon',{staticClass:"ml-2",class:_vm.state ? 'text-success' : 'text-danger',attrs:{"size":"sm","icon":['fas', _vm.state ? 'check' : 'pen']}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }