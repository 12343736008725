<template>
  <b-list-group :key="keys.OptionsList">
    <b-list-group-item
      v-if="type === 'hostess'"
      class="d-flex flex-column flex-nowrap align-items-center justify-content-between px-2"
      :class="msgCostClass"
    >
      <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2 w-100">
        <div
          v-shtml="$t('fCustomer_opt_msgCost', { value: characterFreeChat ? $t('fCustomer_opt_msgCostFreeChatMsg') : ''}) "
          class="small text-nowrap"
        />
        <b-input-group
          style="width: 100px"
        >
          <b-form-input
            ref="characterMsgCost"
            v-model="characterMsgCost"
            type="text"
            size="sm"
            class="text-right"
            style="width: 100px"
            :disabled="isLocked"
            :min="0"
            @input="onChange('characterMsgCost')"
          />
        </b-input-group>
      </div>
      <div
        v-if="(parseInt(characterMsgCost, 10) === 0 || characterMsgCost === '') && isDirty('characterMsgCost')"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2 mt-2 w-100"
      >
        <div class="small text-nowrap text-danger">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
          {{ $t('fCustomer_opt_msgTmpCostZeroCostError') }}
        </div>
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="type === 'hostess'"
      class="d-flex flex-column flex-nowrap align-items-center justify-content-between px-2"
    >
      <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2 w-100">
        <div
          v-shtml="$t('fCustomer_opt_stopp_loss')"
          class="small text-nowrap"
        />
        <b-input-group
          style="width: 100px"
        >
          <b-form-input
            ref="characterStopLoss"
            v-model="characterStopLoss"
            type="text"
            size="sm"
            class="text-right"
            style="width: 100px"
            :disabled="isLocked || status==='inactive'"
            @input="onChange('characterStopLoss')"
          />
        </b-input-group>
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="type === 'hostess'"
      class="d-flex flex-column flex-nowrap align-items-center justify-content-between px-2"
    >
      <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2 w-100">
        <div
          v-shtml="$t('fCustomer_opt_actual_cost')"
          class="small text-nowrap"
        />
        <div>
          {{ (parseInt(characterMsgCost, 10) + parseInt(characterCostModifier, 10)) }}
          <span v-if="characterCostModifier!==0" :class="characterCostModifier>0?'text-success':'text-danger'">({{ characterCostModifier }})</span>
        </div>
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="type === 'hostess'"
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="characterFreeChatClass"
    >
      <div
        class="small text-nowrap d-flex align-items-center"
        :class="characterFreeChat ? 'text-danger' : 'text-success'"
        :title="$t('fCustomer_opt_freeChatTitle')"
      >
        <span class="mr-2">
          <font-awesome-icon v-if="characterFreeChat" :icon="['fas', 'square-check']" />
          <font-awesome-icon v-else :icon="['fas', 'square']" />
        </span>
        <span v-shtml="characterFreeChat ? $t('fCustomer_opt_freeChatYes') : $t('fCustomer_opt_freeChatNo')" />
      </div>
      <b-form-checkbox
        v-model="characterFreeChat"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      v-if="type === 'hostess'"
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="characterWingClass"
    >
      <div
        class="small text-nowrap d-flex align-items-center"
        :class="characterWing ? 'text-danger' : 'text-success'"
        :title="$t('fCustomer_opt_wingTitle')"
      >
        <span class="mr-2">
          <font-awesome-icon v-if="characterWing" :icon="['fas', 'square-check']" />
          <font-awesome-icon v-else :icon="['fas', 'square']" />
        </span>
        <span v-shtml="characterWing ? $t('fCustomer_opt_wingYes') : $t('fCustomer_opt_wingNo')" />
      </div>
      <b-form-checkbox
        v-model="characterWing"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      v-if="type === 'hostess'"
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="privacyClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_privacy') }}:
      </div>
      <b-form-select
        v-if="formData['privacy']"
        v-model="privacy"
        size="sm"
        :options="formData['privacy']"
        style="width: 100px"
        :disabled="isLocked"
      />
    </b-list-group-item>

    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="docVerifiedClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_docVerified') }}:
      </div>
      <b-form-checkbox
        v-model="doc_verified"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="galleryVerifiedClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_galleryVerified') }}:
      </div>
      <b-form-checkbox
        v-model="gallery_verified"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="starsClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_stars') }}:
      </div>
      <span
        v-if="stars_changed"
        class="text-danger small"
      >
        {{ $t('fCustomer_opt_starsChanged') }}
      </span>
      <span
        v-else
        class="text-muted font-italic small"
      >
        ({{ $t('fCustomer_opt_starsCalculated') }})
      </span>
      <b-form-input
        ref="stars"
        v-model="stars"
        type="number"
        size="sm"
        class="text-right"
        style="width: 60px"
        :disabled="isLocked"
        @input="onChange('stars')"
      />
    </b-list-group-item>
    <b-list-group-item
      class="px-2"
      :class="rankingClass"
    >
      <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div class="small text-nowrap">
          {{ $t('fCustomer_opt_ranking') }}:
        </div>
        <span
          v-if="ranking_blocked_value"
          class="text-danger small"
        >
          {{ $t('fCustomer_opt_rankLocked') }} {{ ranking_blocked_value }}
        </span>
        <span
          v-if="ranking_modifier !== 0"
          class="text-danger small"
        >
          {{ $t('fCustomer_opt_rankChanged') }} {{ real_ranking }}
        </span>
        <b-form-input
          ref="ranking"
          v-model="ranking"
          type="number"
          size="sm"
          class="text-right"
          style="width: 60px"
          :disabled="isLocked"
          @input="onChange('ranking')"
        />
      </div>
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="rankingModifierClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_rankingModifier') }}:
      </div>
      <b-form-input
        ref="ranking_modifier"
        v-model="ranking_modifier"
        type="number"
        size="sm"
        class="text-right"
        style="width: 60px"
        :disabled="isLocked"
        @input="onChange('ranking_modifier')"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="disablePurchasesClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_disablePurchases') }}:
      </div>
      <b-form-checkbox
        v-model="disable_purchases"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="isSilencedClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_isSilenced') }}:
      </div>
      <b-form-checkbox
        v-model="isSilenced"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="whitelistedClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_whitelisted') }}:
      </div>
      <b-form-checkbox
        v-model="whitelisted"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="alertMediaClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_alertMedia') }}:
      </div>
      <b-form-checkbox
        v-model="alert_media"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
    <b-list-group-item
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="checkTaskClass"
    >
      <div class="small text-nowrap">
        {{ $t('fCustomer_opt_checkTask') }}:
        <span
          v-if="check_task_enabled"
          :key="check_task_duration"
          class="text-danger small"
        >
          {{ check_task_duration }} {{ $t('fCustomer_opt_day') }}
          ({{ $t('fCustomer_opt_next') }}: {{ checkTaskNextAt }})
        </span>
      </div>
      <div>
        <b-input-group>
          <b-input-group-prepend is-text>
            <input
              v-model="check_task_enabled"
              type="checkbox"
              :disabled="isLocked"
            >
          </b-input-group-prepend>
          <b-form-input
            ref="check_task_duration"
            v-model="check_task_duration"
            :disabled="!check_task_enabled || isLocked"
            type="number"
            size="sm"
            class="text-right"
            style="width: 60px"
            min="0"
            @input="onChange('check_task_duration')"
          />
        </b-input-group>
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="type === 'client'"
      class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
      :class="characterDisableCostModifierClass"
    >
      <div
        class="small text-nowrap d-flex align-items-center"
        :class="characterDisableCostModifier ? 'text-danger' : 'text-success'"
        :title="$t('fCustomer_opt_disableCostModifierTitle')"
      >
        <span class="mr-2">
          <font-awesome-icon v-if="characterDisableCostModifier" :icon="['fas', 'square-check']" />
          <font-awesome-icon v-else :icon="['fas', 'square']" />
        </span>
        <span>{{ $t('fCustomer_opt_disableCostModifierTitle') }}&nbsp;</span>
        <span v-shtml="characterDisableCostModifier ? $t('fCustomer_opt_disableCostModifierYes') : $t('fCustomer_opt_disableCostModifierNo')" />
      </div>
      <b-form-checkbox
        v-model="characterDisableCostModifier"
        size="sm"
        switch
        :disabled="isLocked"
      />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'OptionsList',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      default: -1
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapComputedFormFields('customerEdit', [
      'status',
      'characterMsgCost',
      'characterStopLoss',
      'characterCostModifier',
      'characterFreeChat',
      'characterWing',
      'characterDisableCostModifier',
      'privacy',
      'level',
      'stars',
      'doc_verified',
      'gallery_verified',
      'ranking',
      'ranking_blocked_value',
      'ranking_modifier',
      'real_ranking',
      'stars_changed',
      'disable_purchases',
      'isSilenced',
      'whitelisted',
      'alert_media',
      'check_task_enabled',
      'check_task_duration',
      'check_task_created_at'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'dirty',
      'info',
      'type',
      'editColumnName',
      'editColumnCursor'
    ]),
    levelClass () {
      if (this.isDirty('level')) {
        return 'bg-warning'
      }
      return this.level && this.level !== '' ? 'bg-success' : 'bg-danger'
    },
    privacyClass () {
      if (this.isDirty('privacy')) {
        return 'bg-warning'
      }
      return this?.privacy !== '' && this?.privacy >= 0 ? 'bg-success' : 'bg-danger'
    },
    docVerifiedClass () {
      if (this.isDirty('doc_verified')) {
        return 'bg-warning'
      }
      return this.doc_verified && this.doc_verified !== '' ? 'bg-success' : 'bg-danger'
    },
    galleryVerifiedClass () {
      if (this.isDirty('gallery_verified')) {
        return 'bg-warning'
      }
      return this.gallery_verified && this.gallery_verified !== '' ? 'bg-success' : 'bg-danger'
    },
    rankingClass () {
      if (this.isDirty('ranking')) {
        return 'bg-warning'
      }
      return this.ranking && this.ranking !== '' ? 'bg-success' : 'bg-danger'
    },
    starsValid () {
      if (this.isDirty('stars')) {
        if (this.stars && this.stars !== '') {
          return this.stars >= 0 && this.stars <= 5
        }
      }
      return true
    },
    starsClass () {
      if (this.isDirty('stars')) {
        return this.starsValid ? 'bg-warning' : 'bg-danger'
      }
      return this.starsValid ? 'bg-success' : 'bg-danger'
    },
    rankingModifierClass () {
      if (this.isDirty('ranking_modifier')) {
        return 'bg-warning'
      }
      return this.ranking_modifier && this.ranking_modifier !== '' ? 'bg-success' : 'bg-danger'
    },
    disablePurchasesClass () {
      if (this.isDirty('disable_purchases')) {
        return 'bg-warning'
      }
      return this.disable_purchases && this.disable_purchases !== '' ? 'bg-success' : 'bg-danger'
    },
    isSilencedClass () {
      if (this.isDirty('isSilenced')) {
        return 'bg-warning'
      }
      return this.isSilenced && this.isSilenced !== '' ? 'bg-success' : 'bg-danger'
    },
    whitelistedClass () {
      if (this.isDirty('whitelisted')) {
        return 'bg-warning'
      }
      return this.whitelisted && this.whitelisted !== '' ? 'bg-success' : 'bg-danger'
    },
    alertMediaClass () {
      if (this.isDirty('alert_media')) {
        return 'bg-warning'
      }
      return this.alert_media && this.alert_media !== '' ? 'bg-success' : 'bg-danger'
    },
    checkTaskClass () {
      if (this.isDirty('check_task_enabled') || this.isDirty('check_task_duration')) {
        return 'bg-warning'
      }
      return this.check_task_enabled &&
        this.check_task_duration &&
        this.check_task_duration !== ''
        ? 'bg-success'
        : 'bg-danger'
    },
    msgCostClass () {
      if (this.isDirty('characterMsgCost') || this.isDirty('characterStopLoss')) {
        return parseInt(this.characterMsgCost, 10) > 0 ? 'bg-warning' : 'bg-danger'
      }
      return parseInt(this.characterMsgCost, 10) > 0 ? 'bg-success' : 'bg-danger'
    },
    characterFreeChatClass () {
      if (this.isDirty('characterFreeChat')) {
        return 'bg-warning'
      }
      return ''
    },
    characterWingClass () {
      if (this.isDirty('characterWing')) {
        return 'bg-warning'
      }
      return ''
    },
    characterDisableCostModifierClass () {
      if (this.isDirty('characterDisableCostModifier')) {
        return 'bg-warning'
      }
      return ''
    },
    checkTaskNextAt () {
      const checkTaskCreatedAt = this.check_task_created_at || moment().format('YYYY-MM-DD')
      const checkTaskDuration = parseInt(this.check_task_duration, 10) >= 0
        ? parseInt(this.check_task_duration, 10)
        : 0
      return moment(checkTaskCreatedAt).add(checkTaskDuration, 'day').format('DD/MM/YY')
    }
  },
  watch: {
    form: {
      handler (n) {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  mounted () {
    this.setInputState()
  },
  methods: {
    ...mapActions({
      setError: 'setError'
    }),
    setInputState () {
      // TODO: move that at column's config (ie. `trackCursor: true`)
      const cols = [
        'characterMsgCost',
        'characterStopLoss',
        'stars',
        'ranking',
        'ranking_modifier',
        'check_task_duration'
      ]
      if (cols.includes(this.editColumnName) && this.$refs[this.editColumnName]) {
        this.$refs[this.editColumnName].$el.focus()
        if (this.$refs[this.editColumnName].$el.setSelectionRange && this.editColumnCursor >= 0) {
          this.$refs[this.editColumnName].$el.setSelectionRange(this.editColumnCursor, this.editColumnCursor)
        }
      }
    },
    onChange (colName) {
      this.editColumnName = colName
      if (this.$refs[colName]) {
        const column = this.$refs[colName]
        this.editColumnCursor = (column && column.$el.selectionStart) || -1
      }
      this.$nextTick(() => {
        this.setInputState()
      })
    },
    isDirty (fieldKey) {
      const dirtyFields = this.dirty && Object.keys(this.dirty)
      if (dirtyFields && dirtyFields.length && dirtyFields.includes(fieldKey)) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
