<template>
  <span
    v-if="status"
    :key="keys.UserStatus"
  >
    <font-awesome-icon
      :icon="['fas', 'circle']"
      :class="{
        'text-success': status === 'active',
        'text-warning': status === 'inactive',
        'text-warning': status === 'suspended',
        'text-danger': status === 'deleted',
        'text-primary': status === 'register',
        'text-secondary': status === 'hidden',
      }"
    />&nbsp;
    <span
      v-if="!state || isLocked"
      style="width: 120px"
      @dblclick.prevent.stop="toggle"
    >
      {{ displayStatus }}
    </span>
    <b-form-select
      v-else
      v-model="status"
      :options="options"
      size="sm"
      style="width: 120px"
    />

    <b-link
      v-if="!isLocked"
      @click="toggle"
    >
      <font-awesome-icon
        size="sm"
        class="ml-2"
        :class="state ? 'text-success' : 'text-danger'"
        :icon="['fas', state ? 'check' : 'pen']"
      />
    </b-link>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'UserStatus',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    options: {
      type: Array,
      default: () => ([])
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      default: -1
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName'
    }),
    ...mapComputedFormFields('customerEdit', [
      'status'
    ]),
    ...mapComputedStateFields('customerEdit', []),
    displayStatus () {
      let str = ''
      if (this.options && this.options.length) {
        const item = this.options.find(i => i.value === this.status)
        if (item) {
          str = item.text
        }
      }
      return str
    }
  },
  watch: {
    status (n, o) {
      if (n && n !== o) {
        this.state = false
      }
    }
  },
  methods: {
    toggle () {
      this.state = !this.state
    }
  }
}
</script>

<style scoped>

</style>
