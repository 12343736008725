<template>
  <div
    :key="keys.UserTitle"
    class="col d-flex align-items-center"
  >
    <h1
      v-if="!editColumnState || isLocked"
      class="mb-0 text-gray-900"
      :class="className"
      @dblclick.prevent.stop="toggle"
    >
      {{ display_name }}
      <span
        v-if="control_nickname"
        class="text-muted font-italic"
        style="font-size: 1rem;"
      >({{ control_nickname }})</span>
    </h1>
    <b-form-input
      v-else
      ref="input"
      v-model="display_name"
      type="text"
      @input="onChange"
      @keypress.enter="toggle"
    />
    <button
      class="btn btn-default btn-sm p-0 ml-2"
    />
    <b-link
      v-if="!isLocked"
      @click="toggle"
    >
      <font-awesome-icon
        size="sm"
        class="ml-2"
        :class="editColumnState ? 'text-success' : 'text-danger'"
        :icon="['fas', editColumnState ? 'check' : 'pen']"
      />
    </b-link>
  </div>
</template>

<script>
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'UserTitle',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      default: -1
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapComputedFormFields('customerEdit', [
      'display_name',
      'control_nickname'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'editColumnName',
      'editColumnState',
      'editColumnCursor'
    ]),
    dirty () {
      const form = this.$store.state.forms.customerEdit && this.$store.state.forms.customerEdit.forms.find(i => i.id === this.itemId)
      return form && form.dirty
    },
    className () {
      if (this.isDirty()) {
        return 'bg-warning'
      }
      return ''
    }
  },
  mounted () {
    this.setInputState()
  },
  methods: {
    toggle () {
      this.editColumnState = !this.editColumnState
      this.editColumnName = this.editColumnState ? 'display_name' : ''
      this.$nextTick(() => {
        this.setInputState()
      })
    },
    setInputState () {
      if (this.editColumnName === 'display_name' && this.editColumnState && this.$refs.input) {
        this.$refs.input.focus()
        if (this.$refs.input.setSelectionRange && this.editColumnCursor >= 0) {
          this.$refs.input.setSelectionRange(this.editColumnCursor, this.editColumnCursor)
        }
      }
    },
    onChange () {
      this.editColumnName = 'display_name'
      this.editColumnCursor = (this.editColumnState && this.$refs.input && this.$refs.input.selectionStart) || -1
      this.$nextTick(() => {
        this.setInputState()
      })
    },
    isDirty () {
      const dirtyFields = Object.keys(this.dirty)
      if (dirtyFields.length && dirtyFields.includes('display_name')) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
